<template>
  <CFooter :fixed="false" style="background-color: #00A94F; color: #FFFFFF;">
    <div class="container text-center">
      <span class="ml-1">&copy;{{new Date().getFullYear()}} Masterlot999</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
